import { GetStaticProps } from 'next';
import Index from "../components/pages/Index";
import { fetchEncode } from '../utils/Helper';

const ldJsons = [
    { "@context": "https://schema.org", "@id": "https://ecatalog.sinarmasland.com/#organization", "@type": "Organization", "address": { "@type": "PostalAddress", "addressCountry": "ID", "addressLocality": "Kec. Cisauk, Kota Tangerang", "addressRegion": "Banten", "postalCode": "15345", "streetAddress": "Sinar Mas Land Plaza Jl. Grand Boulevard BSD City, Sampora, Kec. Cisauk" }, "description": "Cari informasi jual beli rumah dan properti online terlengkap dan terbaru dari Sinar Mas Land. Dapatkan harga terbaik langsung dari developer", "founder": [{ "@type": "Person", "name": "Sinar Mas Land", "url": "https://id.wikipedia.org/wiki/Sinar_Mas_Land" }], "image": { "@id": "https://ecatalog.sinarmasland.com/#logo" }, "logo": { "@id": "https://ecatalog.sinarmasland.com/#logo", "@type": "ImageObject", "height": 2048, "url": "https://ecatalog.sinarmasland.com/static/images/homepage_banner.png", "width": 2048 }, "name": "SinarmasLand", "sameAs": ["https://www.facebook.com/sinarmasland/", "https://www.instagram.com/sinarmas_land/", "https://twitter.com/sinarmas_land", "https://www.youtube.com/channel/UCVxIPn2_4HYa18VclGzIqKg", "https://www.linkedin.com/company/sinar-mas-land/", "https://www.tiktok.com/@sinarmas_land"], "telephone": "+62-21-50368368", "url": "https://ecatalog.sinarmasland.com/" },
    { "@context": "https://schema.org", "@type": "BreadcrumbList", "itemListElement": [{ "@type": "ListItem", "item": "https://ecatalog.sinarmasland.com/", "name": "Beranda", "position": 1 }] },
    { "@context": "https://schema.org", "@id": "https://ecatalog.sinarmasland.com/#website", "@type": "WebSite", "description": "Cari informasi jual beli rumah dan properti online terlengkap dan terbaru dari Sinar Mas Land. Dapatkan harga terbaik langsung dari developer", "inLanguage": "id-ID", "name": "SinarmasLand", "potentialAction": { "@type": "SearchAction", "query-input": "required name=search_term_string", "target": "https://ecatalog.sinarmasland.com/search?q={search_term_string}" }, "url": "https://ecatalog.sinarmasland.com/" }
]

export interface HomepageProps {
    listData: {
        "homepage-banner": HomepageBanner[]
        homepage_banner_url: any
        homepage_banner_mobile: string
        homepage_banner_mobile_url: any
        homepage_background_banner_desktop: string
        homepage_background_banner_desktop_url: string
        homepage_background_banner_mobile: string
        homepage_background_banner_mobile_url: string
        projects: Project[]
        listing_counter: ListingCounter
        city_counter: CityCounter
        top_agents: TopAgent[]
        latest_posts: LatestPost[]
        new_listings: NewListing[]
    }
    promotionsData: {
        id: number
        project_id: number
        category: string
        name: string
        slug: string
        address: string
        latitude: number
        longitude: number
        price_start: number
        lowest_studio?: number
        lowest_1_br?: number
        lowest_2_br?: number
        lowest_3_br?: number
        lowest_residential?: number
        lowest_kavling?: number
        lowest_shophouse?: number
        lowest_business_loft: any
        thumbnail: string
        location: string
        project_slug: string
        has_360: boolean
    }[]
}

const homepage = (props: HomepageProps) => {
    return (
        <Index langCode="id" ldJsons={ldJsons} {...props} />
    )
}

export const getStaticProps: GetStaticProps = async () => {
    const [list, promotions] = await Promise.all([fetchEncode(`${process.env.NEXT_PUBLIC_PHP_API_URL}/home/list`), fetchEncode(`${process.env.NEXT_PUBLIC_PHP_API_URL}/home/promotions`)])

    if (!list.ok || !promotions.ok) {
        return {
            props: {
                fallback: true
            },
            revalidate: 600,
        }
    }

    const [listData, promotionsData] = await Promise.all([list.json(), promotions.json()])

    return {
        props: {
            listData,
            promotionsData,
            fallback: true
        },
        revalidate: 600,
    }
}

interface HomepageBanner {
    id: number
    filepath: string
    filepath_mobile: string
    url: string
    sort: number
    image: string
    image_mobile: string
}

export interface Project {
    id: number
    name: string
    highlights?: string
    is_apartment_project: number
    location: string
    url: string
    thumbnail_image: string
    city_name: string
}

interface ListingCounter {
    residential: number
    apartment: number
    shophouse: number
    kiosk: number
    business_loft: number
    warehouse: number
    boutique_soho: number
    kavling: number
}

interface CityCounter {
    jakarta: number
    bsd: number
    cibubur: number
    cikarang: number
    balikpapan: number
    tangerang: number
    batam: number
    surabaya: number
}

export interface TopAgent {
    id: string
    name: string
    slug: string
    unique_code: string
    phone: string
    project: string
    total_cluster: number
    unit_type: number
    profile_picture: string
    profile_description: string
    clusters: Cluster[]
}

export interface Cluster {
    id: number
    project_id: number
    category: string
    name: string
    slug: string
    address: string
    latitude: number
    longitude: number
    price_start: number
    lowest_studio?: number
    lowest_1_br?: number
    lowest_2_br?: number
    lowest_3_br?: number
    lowest_residential?: number
    lowest_kavling: any
    lowest_shophouse: any
    lowest_business_loft?: number
    lowest_kiosk: any
    lowest_warehouse: any
    lowest_boutique_soho?: number
    primary_image_thumbnail: string
    location: string
    project_slug: string
    has_360: boolean
}

interface LatestPost {
    id: number
    title: string
    slug: string
    content_short: string
    created_at: string
    image_url: string
    image_thumbnail_url: string
    category: string
}

export interface NewListing {
    id: string
    name: string
    code: string
    property_type: string
    is_soho: number
    price: number
    property_area: number
    land_area: number
    floor?: string
    bedroom?: number
    bathroom: number
    furnish?: string
    direction?: string
    view?: string
    electric_watt: number
    location: string
    photos: string[]
    url: string
    sold_out: boolean
    cluster_label: string
    cluster_category: string
    has_360: boolean
    is_wishlisted: boolean
}

export default homepage