import React, { useState } from 'react'

interface HomepageArticleProps {
    isMobile: boolean
}

const HomepageArticle = (props: HomepageArticleProps) => {
    const [isExpanded, setIsExpanded] = useState(false)

    return (
        <div className="w-full container py-10 flex flex-col items-center">
            <h2 className="text-center text-xl lg:text-2xl font-bold mb-3 lg:mb-5">
                eCatalog Sinarmas Land Wujudkan Rumah Impian Anda dengan Desain Terbaru & Penawaran Terbaik
            </h2>
            <div
                className={`text-sm lg:text-lg text-justify ${isExpanded ? '' : 'max-h-144px lg:max-h-205px'} whitespace-pre-wrap overflow-hidden mulitline-elipsis mb-6`}
                style={{
                    WebkitLineClamp: isExpanded ? 'unset' : props.isMobile ? 7 : 6,
                }}
            >
                <p className='mb-3'>Apakah Anda sedang mencari rumah impian? eCatalog Sinarmas Land hadir untuk memudahkan Anda menemukan dan membeli properti sesuai keinginan. Platform ini menawarkan berbagai pilihan rumah mulai dari desain rumah minimalis hingga rumah mewah yang elegan. Jelajahi eCatalog Sinarmas Land dan temukan properti yang sesuai dengan kebutuhan Anda.</p>
                <h2 className='text-lg lg:text-xl text-center font-bold my-3 lg:my-5'>Desain Rumah Minimalis dan Model Terbaru</h2>
                <p className='mb-3'>Desain rumah minimalis menjadi pilihan favorit banyak orang karena kesederhanaan dan fungsionalitasnya. Di eCatalog Sinarmas Land, kami menyediakan berbagai model rumah minimalis 2024 yang menggabungkan estetika modern dengan kenyamanan maksimal. Gambar rumah minimalis terbaru di katalog kami menampilkan desain yang segar dan inovatif, memberikan Anda banyak inspirasi untuk memilih rumah yang sesuai dengan kebutuhan dan selera. Setiap rumah minimalis yang kami tawarkan didesain dengan perhatian terhadap detail dan efisiensi ruang, memastikan bahwa setiap sudut rumah dapat dimanfaatkan secara optimal. Model rumah minimalis terbaru ini cocok untuk berbagai ukuran lahan, sehingga Anda bisa menemukan rumah yang pas baik di kawasan perkotaan maupun pinggiran kota.</p>
                <h2 className='text-lg lg:text-xl text-center font-bold my-3 lg:my-5'>Fitur eCatalog Sinarmas Land untuk Pengalaman Mencari Properti yang Lebih Baik</h2>
                <ul className='mb-3'>
                    <li className='mb-1 lg:mb-3'>
                        <strong>KPR Calculator:</strong> Fitur ini dirancang khusus untuk memudahkan Anda dalam merencanakan pembiayaan, Anda dapat menghitung perkiraan angsuran kredit pemilikan rumah (KPR) secara cepat dan akurat.
                    </li>
                    <li className='mb-1 lg:mb-3'>
                        <strong>Virtual Tour 360°:</strong> Jelajahi properti impian Anda secara virtual sebelum mengunjunginya secara langsung.
                    </li>
                    <li className='mb-1 lg:mb-3'>
                        <strong>Compare:</strong> Bandingkan fitur, harga, dan lokasi berbagai properti untuk membuat keputusan yang tepat.
                    </li>
                    <li className='mb-1 lg:mb-3'>
                        <strong>Hot Promo:</strong> Melalui fitur ini, Anda dapat menemukan berbagai promo menarik yang dirancang untuk mempermudah Anda dalam memiliki rumah impian dengan harga yang lebih terjangkau.
                    </li>
                    <li className='mb-1 lg:mb-3'>
                        <strong>Contact Agent:</strong> Anda bisa berkomunikasi dengan agen yang siap membantu Anda dalam setiap tahap proses pembelian properti.
                    </li>
                    <li className='mb-1 lg:mb-3'>
                        <strong>Aerial View:</strong> Fitur ini dirancang untuk memberikan pandangan yang lebih menyeluruh dari perspektif udara yang mendetail tentang lokasi dan lingkungan sekitar properti yang Anda minati.
                    </li>
                </ul>
                <h2 className='text-lg lg:text-xl text-center font-bold my-3 lg:my-5'>Keunggulan Memilih Properti di eCatalog Sinarmas Land</h2>
                <p className='mb-3'>Sinarmas Land adalah pengembang properti terkemuka di Indonesia dengan reputasi yang kuat. Memilih Sinarmas Land berarti Anda mendapatkan jaminan kualitas, lokasi strategis, fasilitas lengkap, dan nilai investasi yang terus berkembang. Jangan tunda lagi impian Anda memiliki rumah sendiri. Jelajahi eCatalog Sinarmas sekarang dan temukan rumah yang sempurna untuk Anda dan keluarga. Kami siap membantu Anda mewujudkan impian memiliki hunian yang nyaman dan berkualitas.</p>
                <p className='mb-3'>Kunjungi E-Catalog Sinarmas Land sekarang dan temukan rumah minimalis impian Anda. Dapatkan informasi lengkap mengenai harga rumah minimalis, brosur perumahan, dan promo menarik lainnya di seluruh Indonesia.</p>
            </div>
            <div className="w-full flex items-center justify-center">
                <button
                    className='bg-white lg:hover:bg-main border border-main rounded text-main lg:hover:text-white text-sm lg:text-base leading-tight px-6 py-4 lg:py-3 focus:outline-none transition-colors duration-150 ease-linear'
                    onClick={() => setIsExpanded(prev => !prev)}
                >
                    {isExpanded ? 'Close' : 'Read more'}
                </button>
            </div>
        </div>
    )
}

export default HomepageArticle
