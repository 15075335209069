import React, { SVGProps } from 'react'

type Props = {}

const VirtualColorIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask id="mask0_421_4362" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="64">
                <circle cx="32" cy="32" r="32" fill="#FFEEEF" />
            </mask>
            <g mask="url(#mask0_421_4362)">
                <circle cx="32" cy="47.9999" r="28.5714" fill="#FED1D3" fill-opacity="0.5" />
                <path d="M33.7787 15.7917C33.1943 15.1834 32.2212 15.1834 31.6368 15.7917L15.1354 32.969V54.4777C15.1354 55.6805 16.1105 56.6557 17.3133 56.6557H48.102C49.3049 56.6557 50.28 55.6805 50.28 54.4777C50.28 54.0339 50.28 34.003 50.28 32.969L33.7787 15.7917Z" fill="white" />
                <path d="M33.778 15.7917C33.1936 15.1834 32.2205 15.1834 31.6361 15.7917L28.6598 18.89C29.1205 18.8193 29.6079 18.9639 29.9569 19.3272L46.4583 36.5045V56.6558H48.1014C49.3042 56.6558 50.2793 55.6806 50.2793 54.4778C50.2793 54.034 50.2793 34.0031 50.2793 32.969L33.778 15.7917Z" fill="#F8F8F8" />
                <mask id="mask1_421_4362" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="38" y="36" width="26" height="22">
                    <path d="M50.3998 47.9991L39.5426 49.7134L38.9712 56.5706L47.5426 57.142L60.6855 55.4277L63.5426 38.2848L50.3998 36.5706V47.9991Z" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask1_421_4362)">
                    <path d="M42.1707 53.7614C41.1973 53.7614 40.3443 53.2462 40.1959 52.5284C40.0324 51.7381 40.7852 51.0015 41.8772 50.8831C46.7184 50.3586 50.9918 49.4278 53.9104 48.2619C56.4014 47.2669 57.8301 46.1257 57.8301 45.1308C57.8301 43.8376 55.5139 42.3742 51.6342 41.2163C47.0838 39.8582 40.8795 39.033 34.1643 38.8925C33.0605 38.8694 32.1917 38.2032 32.2236 37.4044C32.2556 36.6056 33.1779 35.9775 34.2798 35.9998C41.4671 36.1501 48.1716 37.0526 53.1587 38.541C55.7295 39.3082 57.7702 40.2076 59.2243 41.2141C60.9282 42.3937 61.8288 43.748 61.8288 45.1309C61.8288 46.6243 60.7902 48.8193 55.8426 50.7956C52.47 52.1428 47.8456 53.1628 42.4692 53.7452C42.369 53.7561 42.2691 53.7614 42.1707 53.7614Z" fill="#757575" />
                </g>
                <mask id="mask2_421_4362" style={{maskType: 'alpha'}} maskUnits="userSpaceOnUse" x="-1" y="37" width="40" height="25">
                    <path d="M38.2851 50.2858L37.7137 57.7144L27.9994 61.1429L-0.572021 59.4286L0.570836 41.1429L15.428 37.7144L14.8566 46.2858L27.9994 46.8572L38.2851 50.2858Z" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask2_421_4362)">
                    <path d="M33.1422 51.3916C25.9784 51.3916 19.2733 50.6241 14.2618 49.2306C10.0458 48.0583 7.42664 46.4877 7.42664 45.132C7.42664 44.9181 7.49024 44.6994 7.61386 44.4778H3.49402C3.44783 44.7063 3.42798 44.9251 3.42798 45.132C3.42798 47.8316 6.76619 50.2466 12.8278 51.9321C18.2857 53.4497 25.5001 54.2856 33.1422 54.2856C34.2464 54.2856 35.1416 53.6377 35.1416 52.8386C35.1416 52.0395 34.2464 51.3916 33.1422 51.3916Z" fill="#757575" />
                    <path d="M5.42731 46.5779C4.32312 46.5779 3.42798 45.9301 3.42798 45.131C3.42798 43.4911 4.66391 41.1033 10.5522 39.0408C14.5675 37.6343 20.1604 36.6281 26.3009 36.2074C27.3993 36.1325 28.3757 36.716 28.4798 37.5116C28.5838 38.3072 27.777 39.0131 26.6777 39.0885C20.9997 39.4774 15.8915 40.3856 12.2943 41.6456C9.20079 42.7292 7.42664 43.9996 7.42664 45.131C7.42664 45.9301 6.53149 46.5779 5.42731 46.5779Z" fill="#616161" />
                    <path d="M31.6321 48.765L36.1663 51.6083C36.9493 52.0993 36.9493 53.24 36.1663 53.731L31.6321 56.5743C30.7977 57.0975 29.7137 56.4978 29.7137 55.5129V49.8265C29.7137 48.8415 30.7977 48.2417 31.6321 48.765Z" fill="#757575" />
                </g>
                <path d="M32.0238 17.3809L12.8069 37.385C12.4228 37.7849 11.785 37.7913 11.393 37.3992L9.43257 35.4388C9.05139 35.0576 9.04522 34.4416 9.41863 34.0529L31.6668 10.8932C32.446 10.0821 33.7434 10.0821 34.5225 10.8932L56.7708 34.0529C57.1442 34.4417 57.138 35.0576 56.7569 35.4388L54.7965 37.3992C54.4044 37.7913 53.7668 37.7849 53.3826 37.385L34.1657 17.3809C33.5813 16.7726 32.6082 16.7726 32.0238 17.3809Z" fill="#EF5350" />
                <path d="M44.1179 38.1086C42.8895 38.1086 41.8899 37.1091 41.8899 35.8806C41.8899 34.6521 42.8894 33.6526 44.1179 33.6526C45.3463 33.6526 46.3459 34.6521 46.3459 35.8806C46.3458 37.1092 45.3463 38.1086 44.1179 38.1086ZM44.1179 35.2127C43.7496 35.2127 43.4499 35.5123 43.4499 35.8807C43.4499 36.249 43.7496 36.5487 44.1179 36.5487C44.4862 36.5487 44.7859 36.2491 44.7859 35.8807C44.7858 35.5123 44.4861 35.2127 44.1179 35.2127Z" fill="#FFAB02" />
                <path d="M44.1179 37.5993C42.8895 37.5993 41.8899 36.5998 41.8899 35.3713C41.8899 34.1428 42.8894 33.1433 44.1179 33.1433C45.3463 33.1433 46.3459 34.1428 46.3459 35.3713C46.3458 36.5999 45.3463 37.5993 44.1179 37.5993ZM44.1179 34.7034C43.7496 34.7034 43.4499 35.003 43.4499 35.3714C43.4499 35.7397 43.7496 36.0394 44.1179 36.0394C44.4862 36.0394 44.7859 35.7398 44.7859 35.3714C44.7858 35.003 44.4861 34.7034 44.1179 34.7034Z" fill="#FDCF17" />
                <path d="M24.4885 42.0774V41.8293C24.4885 40.9545 23.9531 40.7848 23.2351 40.7848C22.7911 40.7848 22.6474 40.3931 22.6474 40.0013C22.6474 39.6096 22.791 39.2179 23.2351 39.2179C23.7312 39.2179 24.2536 39.1525 24.2536 38.095C24.2536 37.3376 23.8226 37.1548 23.2873 37.1548C22.6475 37.1548 22.321 37.3115 22.321 37.8207C22.321 38.2647 22.1252 38.565 21.3679 38.565C20.4278 38.565 20.3103 38.369 20.3103 37.7424C20.3103 36.7238 21.0414 35.405 23.2873 35.405C24.9456 35.405 26.1991 36.0057 26.1991 37.7684C26.1991 38.7215 25.8464 39.6094 25.1937 39.9098C25.9639 40.197 26.5256 40.7716 26.5256 41.8292V42.0773C26.5256 44.2187 25.0501 45.0283 23.222 45.0283C20.9762 45.0283 20.1143 43.6572 20.1143 42.5605C20.1143 41.9729 20.3624 41.8162 21.0806 41.8162C21.9163 41.8162 22.1252 41.999 22.1252 42.4952C22.1252 43.109 22.6996 43.2526 23.2873 43.2526C24.1751 43.2526 24.4885 42.9262 24.4885 42.0774Z" fill="#FFAB02" />
                <path d="M33.7989 41.8291V41.9466C33.7989 44.1923 32.4018 45.028 30.5999 45.028C28.798 45.028 27.3878 44.1923 27.3878 41.9466V38.4864C27.3878 36.2405 28.8372 35.4048 30.7174 35.4048C32.9241 35.4048 33.7989 36.7758 33.7989 37.8596C33.7989 38.4864 33.4986 38.6822 32.8458 38.6822C32.2843 38.6822 31.7882 38.5386 31.7882 37.9379C31.7882 37.4417 31.2658 37.1806 30.6521 37.1806C29.8818 37.1806 29.4248 37.5854 29.4248 38.4864V39.6615C29.8426 39.2045 30.43 39.0869 31.0569 39.0869C32.5454 39.087 33.7989 39.7399 33.7989 41.8291ZM29.4248 42.0903C29.4248 42.9912 29.8686 43.3828 30.5999 43.3828C31.3311 43.3828 31.762 42.9912 31.762 42.0903V41.9728C31.762 41.0196 31.3311 40.654 30.5869 40.654C29.8818 40.654 29.4248 40.9935 29.4248 41.8553V42.0903Z" fill="#FFAB02" />
                <path d="M34.7266 41.9466V38.4864C34.7266 36.2405 36.1237 35.4048 37.9256 35.4048C39.7275 35.4048 41.1376 36.2405 41.1376 38.4864V41.9466C41.1376 44.1923 39.7275 45.028 37.9256 45.028C36.1237 45.028 34.7266 44.1923 34.7266 41.9466ZM39.1007 38.4864C39.1007 37.5854 38.6569 37.1806 37.9256 37.1806C37.1944 37.1806 36.7635 37.5854 36.7635 38.4864V41.9466C36.7635 42.8475 37.1944 43.2523 37.9256 43.2523C38.6569 43.2523 39.1007 42.8474 39.1007 41.9466V38.4864Z" fill="#FFAB02" />
                <path d="M24.328 41.4719V41.2239C24.328 40.349 23.7926 40.1794 23.0745 40.1794C22.6306 40.1794 22.4869 39.7876 22.4869 39.3959C22.4869 39.0041 22.6305 38.6124 23.0745 38.6124C23.5706 38.6124 24.0931 38.547 24.0931 37.4895C24.0931 36.7321 23.6621 36.5493 23.1268 36.5493C22.487 36.5493 22.1605 36.706 22.1605 37.2152C22.1605 37.6592 21.9647 37.9595 21.2074 37.9595C20.2672 37.9595 20.1498 37.7636 20.1498 37.1369C20.1498 36.1184 20.8809 34.7996 23.1268 34.7996C24.7851 34.7996 26.0385 35.4002 26.0385 37.1629C26.0385 38.1161 25.6859 39.0039 25.0331 39.3043C25.8034 39.5916 26.3651 40.1661 26.3651 41.2238V41.4718C26.3651 43.6132 24.8896 44.4228 23.0614 44.4228C20.8157 44.4228 19.9537 43.0518 19.9537 41.955C19.9537 41.3675 20.2019 41.2108 20.92 41.2108C21.7557 41.2108 21.9647 41.3936 21.9647 41.8898C21.9647 42.5036 22.5391 42.6471 23.1268 42.6471C24.0146 42.6471 24.328 42.3208 24.328 41.4719Z" fill="#FDCF17" />
                <path d="M33.6376 41.2239V41.3413C33.6376 43.5871 32.2405 44.4228 30.4386 44.4228C28.6367 44.4228 27.2266 43.5871 27.2266 41.3413V37.8811C27.2266 35.6353 28.6759 34.7996 30.5562 34.7996C32.7628 34.7996 33.6376 36.1706 33.6376 37.2544C33.6376 37.8811 33.3374 38.077 32.6846 38.077C32.123 38.077 31.6269 37.9334 31.6269 37.3327C31.6269 36.8365 31.1045 36.5753 30.4908 36.5753C29.7206 36.5753 29.2635 36.9802 29.2635 37.8811V39.0562C29.6814 38.5993 30.2688 38.4817 30.8957 38.4817C32.3842 38.4818 33.6376 39.1347 33.6376 41.2239ZM29.2635 41.485C29.2635 42.386 29.7074 42.7776 30.4386 42.7776C31.1699 42.7776 31.6007 42.386 31.6007 41.485V41.3676C31.6007 40.4144 31.1699 40.0488 30.4256 40.0488C29.7206 40.0488 29.2635 40.3883 29.2635 41.2501V41.485Z" fill="#FDCF17" />
                <path d="M34.5652 41.3413V37.8811C34.5652 35.6353 35.9623 34.7996 37.7642 34.7996C39.5661 34.7996 40.9763 35.6353 40.9763 37.8811V41.3413C40.9763 43.5871 39.5661 44.4228 37.7642 44.4228C35.9623 44.4228 34.5652 43.5871 34.5652 41.3413ZM38.9393 37.8811C38.9393 36.9802 38.4955 36.5753 37.7642 36.5753C37.033 36.5753 36.6021 36.9802 36.6021 37.8811V41.3413C36.6021 42.2423 37.033 42.647 37.7642 42.647C38.4955 42.647 38.9393 42.2422 38.9393 41.3413V37.8811Z" fill="#FDCF17" />
            </g>
        </svg>

    )
}

export default VirtualColorIcon