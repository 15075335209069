import React, { SVGProps } from 'react'

type Props = {}

const ContactAgentColorIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask id="mask0_421_4406" style={{maskType: "alpha"}} maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="64">
                <circle cx="32" cy="32" r="32" fill="#FFEEEF" />
            </mask>
            <g mask="url(#mask0_421_4406)">
                <circle cx="31.9999" cy="47.9999" r="28.5714" fill="#FED1D3" fill-opacity="0.5" />
                <path d="M31.447 5.7146C30.821 5.7146 30.213 5.92422 29.72 6.31004L8.25977 23.105L10.9746 26.5637L31.447 10.5453L51.9193 26.5637L54.6342 23.105L33.1739 6.31004C32.6809 5.92422 32.073 5.7146 31.447 5.7146Z" fill="#EF5350" />
                <path d="M48.837 18.568V5.7146H41.1079V12.5191L48.837 18.568Z" fill="#757575" />
                <path d="M48.8367 24.1481V35.6644H14.0559V24.1481L31.4463 10.5449L48.8367 24.1481Z" fill="white" />
                <path d="M48.7845 24.1075L31.4463 10.5449L14.0559 24.1481V27.8319L31.4463 14.2249L45.9383 25.5635V35.6644H48.8367V24.1481L48.7845 24.1075Z" fill="#F8F8F8" />
                <path d="M44.0063 34.699H34.345V28.9022H44.0063V34.699Z" fill="#D5E3F2" />
                <path d="M18.887 28.9021H28.5483V35.665H18.887V28.9021Z" fill="#757575" />
                <path d="M28.5483 28.9021V34.6989H18.887V33.7328H21.7854C22.8103 33.7328 23.7933 33.3256 24.518 32.6009C25.2427 31.8761 25.6499 30.8932 25.6499 29.8682V28.9021H28.5483Z" fill="#616161" />
                <path d="M44.0063 28.9021V34.6989H34.345V33.7328H37.2434C38.2683 33.7328 39.2513 33.3256 39.976 32.6009C40.7007 31.8761 41.1079 30.8932 41.1079 29.8682V28.9021H44.0063Z" fill="#BBC9D9" />
                <path d="M8.25941 32.7659H-1.71411L3.42875 56.9192H8.25941C8.77188 56.9192 9.26336 56.7156 9.62573 56.3532C9.9881 55.9909 10.1917 55.4994 10.1917 54.9869V34.6981C10.1917 34.1857 9.9881 33.6942 9.62573 33.3318C9.26336 32.9694 8.77188 32.7659 8.25941 32.7659Z" fill="#FDCF17" />
                <path d="M8.25938 32.7659H5.36098C5.87345 32.7659 6.36493 32.9694 6.7273 33.3318C7.08967 33.6942 7.29324 34.1857 7.29324 34.6981V52.0885C7.29324 52.601 7.08967 53.0925 6.7273 53.4548C6.36493 53.8172 5.87345 54.0208 5.36098 54.0208H3.42871V56.9192H8.25938C8.77184 56.9192 9.26332 56.7156 9.62569 56.3532C9.98806 55.9909 10.1916 55.4994 10.1916 54.9869V34.6981C10.1916 34.1857 9.98806 33.6942 9.62569 33.3318C9.26332 32.9694 8.77184 32.7659 8.25938 32.7659Z" fill="#FFAB02" />
                <path d="M54.6339 32.766C54.1215 32.766 53.63 32.9696 53.2676 33.3319C52.9052 33.6943 52.7017 34.1858 52.7017 34.6982V54.987C52.7017 55.4995 52.9052 55.991 53.2676 56.3534C53.63 56.7157 54.1215 56.9193 54.6339 56.9193H59.4646L65.7145 32.5718L54.6339 32.766Z" fill="#EF5350" />
                <path d="M46.9511 54.0672L46.9047 54.0208L29.6801 36.7962C28.9553 36.0715 27.9724 35.6643 26.9474 35.6643H10.1917V54.0208H14.3877C15.4126 54.0208 16.3956 54.428 17.1203 55.1527L22.9171 60.9495C23.4299 61.4608 24.1245 61.7477 24.8486 61.7471C25.5727 61.7466 26.2669 61.4588 26.7789 60.9468C27.2909 60.4348 27.5788 59.7405 27.5793 59.0165C27.5798 58.2924 27.2929 57.5977 26.7817 57.085L29.6801 59.9834C29.9337 60.2377 30.235 60.4396 30.5667 60.5774C30.8983 60.7152 31.254 60.7863 31.6131 60.7865C31.9723 60.7868 32.328 60.7162 32.6599 60.5789C32.9918 60.4415 33.2934 60.2401 33.5474 59.9861C33.8013 59.7322 34.0028 59.4306 34.1401 59.0987C34.2774 58.7668 34.348 58.4111 34.3477 58.0519C34.3475 57.6927 34.2764 57.3371 34.1386 57.0054C34.0008 56.6737 33.7989 56.3725 33.5446 56.1189L36.443 59.0173C36.9557 59.5285 37.6504 59.8154 38.3745 59.8149C39.0985 59.8144 39.7928 59.5265 40.3048 59.0145C40.8168 58.5025 41.1046 57.8083 41.1051 57.0842C41.1057 56.3601 40.8188 55.6654 40.3075 55.1527L43.2044 58.0496C43.6955 58.5421 44.3561 58.8284 45.0513 58.8502C45.7464 58.8719 46.4236 58.6274 46.9446 58.1667C47.2207 57.9016 47.4405 57.5836 47.5909 57.2317C47.7413 56.8797 47.8191 56.501 47.8197 56.1183C47.8203 55.7356 47.7437 55.3567 47.5944 55.0042C47.4452 54.6518 47.2264 54.3331 46.9511 54.0672Z" fill="#FFCCBC" />
                <path d="M27.5724 59.2088C27.5305 59.866 27.2486 60.4849 26.7802 60.9478C26.527 61.2027 26.226 61.405 25.8944 61.543C25.5628 61.6811 25.2071 61.7522 24.8479 61.7522C24.4887 61.7522 24.133 61.6811 23.8014 61.543C23.4698 61.405 23.1687 61.2027 22.9156 60.9478L17.1188 55.151C16.3932 54.4268 15.4098 54.0203 14.3847 54.0206H10.1917V52.0884H15.4088C15.9149 52.0882 16.4162 52.188 16.8837 52.382C17.3512 52.576 17.7759 52.8603 18.1333 53.2188L23.9301 59.0156C24.4061 59.492 25.0416 59.7753 25.7142 59.811C26.3867 59.8467 27.0486 59.6322 27.5724 59.2088Z" fill="#FFAB91" />
                <path d="M34.3453 58.2428C34.3034 58.9 34.0215 59.5189 33.5531 59.9819C33.2999 60.2367 32.9989 60.439 32.6673 60.5771C32.3357 60.7151 31.98 60.7862 31.6208 60.7862C31.2616 60.7862 30.9059 60.7151 30.5743 60.5771C30.2427 60.439 29.9416 60.2367 29.6885 59.9819L25.9097 56.1949C25.7152 56 25.6061 55.7359 25.6062 55.4606C25.6063 55.1853 25.7158 54.9213 25.9105 54.7266C26.1053 54.5318 26.3697 54.4221 26.6452 54.4221C26.9207 54.4221 27.185 54.5316 27.3798 54.7264L30.703 58.0496C31.179 58.526 31.8145 58.8094 32.4871 58.845C33.1596 58.8807 33.8215 58.6662 34.3453 58.2428Z" fill="#FFAB91" />
                <path d="M41.1076 57.2771C41.0658 57.9343 40.7838 58.5532 40.3154 59.0161C40.0623 59.271 39.7613 59.4733 39.4296 59.6113C39.098 59.7494 38.7424 59.8205 38.3831 59.8205C38.0239 59.8205 37.6683 59.7494 37.3366 59.6113C37.005 59.4733 36.704 59.271 36.4509 59.0161L31.7062 54.263C31.5117 54.0682 31.4025 53.804 31.4026 53.5287C31.4027 53.2533 31.5121 52.9893 31.7068 52.7946C31.9017 52.5998 32.166 52.4902 32.4415 52.4902C32.717 52.4902 32.9812 52.5997 33.176 52.7945L37.4653 57.0838C37.9413 57.5603 38.5769 57.8436 39.2494 57.8793C39.9219 57.915 40.5839 57.7004 41.1076 57.2771Z" fill="#FFAB91" />
                <path d="M47.8706 56.3101C47.8287 56.9673 47.5467 57.5862 47.0783 58.0491C46.8252 58.304 46.5242 58.5063 46.1926 58.6444C45.8609 58.7824 45.5053 58.8535 45.1461 58.8535C44.7869 58.8535 44.4312 58.7824 44.0996 58.6444C43.768 58.5063 43.4669 58.304 43.2138 58.0491L37.5032 52.3299C37.3086 52.135 37.1994 51.8709 37.1995 51.5955C37.1996 51.3202 37.309 51.0561 37.5037 50.8614C37.6985 50.6666 37.9627 50.5571 38.2383 50.5571C38.5138 50.5571 38.778 50.6666 38.9728 50.8614L44.2282 56.1169C44.7043 56.5933 45.3398 56.8766 46.0123 56.9123C46.6849 56.948 47.3468 56.7335 47.8706 56.3101Z" fill="#FFAB91" />
                <path d="M35.9507 35.6643C35.4418 35.6643 34.9379 35.7648 34.468 35.9601C33.998 36.1554 33.5713 36.4415 33.2122 36.8022L26.4263 43.6176C25.9292 44.1147 25.6499 44.789 25.6499 45.492C25.6499 46.1951 25.9292 46.8694 26.4263 47.3665L26.4748 47.415C26.7282 47.6705 27.0295 47.8735 27.3615 48.0123C27.6935 48.1511 28.0496 48.2231 28.4094 48.224C28.7693 48.225 29.1257 48.1549 29.4584 48.0178C29.7911 47.8807 30.0935 47.6793 30.3482 47.4251C32.347 45.4263 35.3152 42.4312 35.3152 42.4312L46.9048 54.0208H52.7016V35.6643H35.9507Z" fill="#FFAB91" />
                <path d="M54.6339 32.7659H57.5323C57.0199 32.7659 56.5284 32.9694 56.166 33.3318C55.8036 33.6942 55.6001 34.1857 55.6001 34.6981V52.0885C55.6001 52.601 55.8036 53.0925 56.166 53.4548C56.5284 53.8172 57.0199 54.0208 57.5323 54.0208H59.4646V56.9192H54.6339C54.1215 56.9192 53.63 56.7156 53.2676 56.3532C52.9052 55.9909 52.7017 55.4994 52.7017 54.9869V34.6981C52.7017 34.1857 52.9052 33.6942 53.2676 33.3318C53.63 32.9694 54.1215 32.7659 54.6339 32.7659Z" fill="#E53935" />
                <path d="M41.1079 8.83838V12.5184L48.837 18.5673V14.8873L41.1079 8.83838Z" fill="#616161" />
                <path d="M38.2091 41.4616V41.3116C38.2094 40.9291 38.0961 40.5552 37.8836 40.2372C37.6711 39.9191 37.3689 39.6714 37.0154 39.5252C36.662 39.3791 36.2731 39.3412 35.898 39.4163C35.523 39.4914 35.1787 39.6762 34.9087 39.9471C32.8686 41.9965 30.4435 44.4315 29.3816 45.4934C28.8915 45.9809 28.2345 46.2639 27.5436 46.2852C26.8527 46.3064 26.1795 46.0643 25.6604 45.6079C25.6852 46.2697 25.9585 46.8978 26.4258 47.3671L26.4743 47.4155C26.7277 47.671 27.029 47.874 27.361 48.0128C27.6929 48.1517 28.0491 48.2236 28.4089 48.2246C28.7687 48.2255 29.1252 48.1554 29.4579 48.0183C29.7906 47.8812 30.093 47.6798 30.3477 47.4257C32.3465 45.4268 36.2769 41.4616 36.2769 41.4616C36.2769 42.7428 36.7858 43.9715 37.6917 44.8774C38.5977 45.7834 39.8264 46.2923 41.1075 46.2923H44.0059C44.2622 46.2923 44.5079 46.1905 44.6891 46.0093C44.8703 45.8281 44.9721 45.5824 44.9721 45.3262C44.9721 45.0699 44.8703 44.8242 44.6891 44.643C44.5079 44.4618 44.2622 44.36 44.0059 44.36H41.1075C40.3388 44.36 39.6016 44.0547 39.0581 43.5111C38.5145 42.9676 38.2091 42.2303 38.2091 41.4616Z" fill="#FF8A65" />
            </g>
        </svg>

    )
}

export default ContactAgentColorIcon