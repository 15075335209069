import React, { SVGProps } from 'react'

type Props = {}

const AerialViewColorIcon = (props: SVGProps<SVGSVGElement>) => {
    return (
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <mask id="mask0_421_4435" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="0" width="64" height="64">
                <circle cx="32" cy="32" r="32" fill="#FFEEEF" />
            </mask>
            <g mask="url(#mask0_421_4435)">
                <circle cx="31.9999" cy="47.9999" r="28.5714" fill="#FED1D3" fill-opacity="0.5" />
                <g clip-path="url(#clip0_421_4435)">
                    <path d="M32.0903 23.9375C31.4003 23.9375 30.8403 23.3775 30.8403 22.6875V18.625C30.8403 17.935 31.4003 17.375 32.0903 17.375C32.7803 17.375 33.3403 17.935 33.3403 18.625V22.6875C33.3403 23.3775 32.7816 23.9375 32.0903 23.9375Z" fill="#757575" />
                    <path d="M36.1528 30.5H28.0278C26.8216 30.5 25.8403 29.5188 25.8403 28.3125V23.9375C25.8403 22.7313 26.8216 21.75 28.0278 21.75H36.1528C37.3591 21.75 38.3403 22.7313 38.3403 23.9375V28.3125C38.3403 29.5188 37.3591 30.5 36.1528 30.5Z" fill="#757575" />
                    <path d="M34.5903 26.125C34.5903 27.505 33.4703 28.625 32.0903 28.625C30.7103 28.625 29.5903 27.505 29.5903 26.125C29.5903 24.7438 30.7103 23.625 32.0903 23.625C33.4703 23.625 34.5903 24.7438 34.5903 26.125Z" fill="white" />
                    <path d="M40.5278 12.375H23.6528C22.9628 12.375 22.4028 12.935 22.4028 13.625C22.4028 14.315 22.9628 14.875 23.6528 14.875H27.0903V15.8125C27.0903 17.7088 28.6316 19.25 30.5278 19.25H33.6528C35.5491 19.25 37.0903 17.7088 37.0903 15.8125V14.875H40.5278C41.2178 14.875 41.7778 14.315 41.7778 13.625C41.7778 12.935 41.2178 12.375 40.5278 12.375Z" fill="#EF5350" />
                    <path d="M24.5903 8H18.3403C17.6503 8 17.0903 7.44 17.0903 6.75C17.0903 6.06 17.6503 5.5 18.3403 5.5H24.5903C25.2803 5.5 25.8403 6.06 25.8403 6.75C25.8403 7.44 25.2803 8 24.5903 8Z" fill="#607D8B" />
                    <path d="M21.4653 12.6875C20.7753 12.6875 20.2153 12.1275 20.2153 11.4375V6.75C20.2153 6.06 20.7753 5.5 21.4653 5.5C22.1553 5.5 22.7153 6.06 22.7153 6.75V11.4375C22.7153 12.1275 22.1553 12.6875 21.4653 12.6875Z" fill="#607D8B" />
                    <path d="M45.8403 8H39.5903C38.9003 8 38.3403 7.44 38.3403 6.75C38.3403 6.06 38.9003 5.5 39.5903 5.5H45.8403C46.5303 5.5 47.0903 6.06 47.0903 6.75C47.0903 7.44 46.5303 8 45.8403 8Z" fill="#616161" />
                    <path d="M42.7153 12.6875C42.0253 12.6875 41.4653 12.1275 41.4653 11.4375V6.75C41.4653 6.06 42.0253 5.5 42.7153 5.5C43.4053 5.5 43.9653 6.06 43.9653 6.75V11.4375C43.9653 12.1275 43.4053 12.6875 42.7153 12.6875Z" fill="#616161" />
                    <path d="M22.4028 16.75H20.5278C19.3216 16.75 18.3403 15.7687 18.3403 14.5625V12.6875C18.3403 11.4812 19.3216 10.5 20.5278 10.5H22.4028C23.6091 10.5 24.5903 11.4812 24.5903 12.6875V14.5625C24.5903 15.7687 23.6091 16.75 22.4028 16.75Z" fill="#00BCD4" />
                    <path d="M43.6528 16.75H41.7778C40.5716 16.75 39.5903 15.7687 39.5903 14.5625V12.6875C39.5903 11.4812 40.5716 10.5 41.7778 10.5H43.6528C44.8591 10.5 45.8403 11.4812 45.8403 12.6875V14.5625C45.8403 15.7687 44.8591 16.75 43.6528 16.75Z" fill="#EF5350" />
                    <path d="M32.0903 19.25H30.8403V21.75H32.0903V19.25Z" fill="#616161" />
                    <path d="M32.0903 21.75H30.8403H28.0278C26.8216 21.75 25.8403 22.7313 25.8403 23.9375V28.3125C25.8403 29.5188 26.8216 30.5 28.0278 30.5H32.0903V28.625C30.7103 28.625 29.5903 27.505 29.5903 26.125C29.5903 24.7438 30.7103 23.625 32.0903 23.625V21.75Z" fill="#616161" />
                    <path d="M32.0903 23.625C30.7103 23.625 29.5903 24.7438 29.5903 26.125C29.5903 27.505 30.7103 28.625 32.0903 28.625V23.625Z" fill="#DEDEDE" />
                    <path d="M32.0904 12.375H24.5679C24.5829 12.4775 24.5904 12.5813 24.5904 12.6875V14.5625C24.5904 14.6688 24.5829 14.7725 24.5679 14.875H27.0904V15.8125C27.0904 17.7088 28.6316 19.25 30.5279 19.25H30.8404H32.0904V12.375Z" fill="#E53935" />
                    <path d="M21.4653 5.5H18.3403C17.6503 5.5 17.0903 6.06 17.0903 6.75C17.0903 7.44 17.6503 8 18.3403 8H20.2153V6.75C20.2153 6.06 20.7753 5.5 21.4653 5.5ZM24.5903 5.5H21.4653C22.1553 5.5 22.7153 6.06 22.7153 6.75V8H24.5903C25.2803 8 25.8403 7.44 25.8403 6.75C25.8403 6.06 25.2803 5.5 24.5903 5.5Z" fill="#616161" />
                    <path d="M21.4653 5.5C20.7753 5.5 20.2153 6.06 20.2153 6.75V8V10.5225C20.3178 10.5075 20.4216 10.5 20.5278 10.5H22.4028C22.5091 10.5 22.6128 10.5075 22.7153 10.5225V8V6.75C22.7153 6.06 22.1553 5.5 21.4653 5.5Z" fill="#616161" />
                    <path d="M22.4028 10.5H20.5278C20.4216 10.5 20.3178 10.5075 20.2153 10.5225C19.1566 10.6738 18.3403 11.5875 18.3403 12.6875V14.5625C18.3403 15.7687 19.3216 16.75 20.5278 16.75H22.4028C23.5028 16.75 24.4153 15.9337 24.5678 14.875C24.5828 14.7725 24.5903 14.6687 24.5903 14.5625V12.6875C24.5903 12.5812 24.5828 12.4775 24.5678 12.375C24.4303 11.4187 23.6716 10.66 22.7153 10.5225C22.6128 10.5075 22.5091 10.5 22.4028 10.5Z" fill="#E53935" />
                </g>
                <path d="M67.0239 66.8834L61.5182 36.5502C61.4633 36.2475 61.2755 35.9852 61.0064 35.8356L47.1489 28.1281C46.969 28.0279 46.7674 27.9881 46.5693 28.0034L49.0905 60.5262L49.006 60.564L65.5904 68.0041C65.7254 68.0645 65.8688 68.0943 66.0116 68.0943C66.2376 68.0943 66.4615 68.02 66.6456 67.8759C66.9464 67.641 67.0919 67.2589 67.0239 66.8834Z" fill="#BBC9D9" />
                <path d="M32.0002 35.4965L17.8274 28.1146C17.6575 28.0261 17.4692 27.9899 17.2837 28.0024C17.3329 27.9994 17.3823 27.9997 17.4313 28.0035L14.9102 60.5263L31.579 68.0043C31.7136 68.0646 31.8572 68.0945 32.0002 68.0945V35.4965Z" fill="#BBC9D9" />
                <path opacity="0.8" d="M17.4311 28.0029C17.233 27.9876 17.0314 28.0274 16.8516 28.1275L2.99384 35.835C2.72474 35.9846 2.53695 36.2467 2.48206 36.5496L-3.02349 66.8828C-3.09166 67.2581 -2.94602 67.6403 -2.64531 67.8754C-2.46093 68.0195 -2.23714 68.0939 -2.0113 68.0939C-1.86854 68.0939 -1.72495 68.0642 -1.59013 68.0037L14.9039 60.604L17.4311 28.0029Z" fill="white" />
                <path opacity="0.8" d="M46.5689 28.0024C46.4326 28.013 46.2978 28.0493 46.1728 28.1144L32 35.4961V68.0942H32.0001C32.1432 68.0942 32.2869 68.0643 32.4213 68.004L49.0902 60.5259L46.5689 28.0024Z" fill="white" />
                <path d="M0.813236 45.7423L-0.305176 51.9043L16.256 43.1571L16.6405 38.1969L16.7042 37.3743L0.813236 45.7423Z" fill="#FFDB56" />
                <path d="M16.4053 41.2334L16.2563 43.1568L-0.304867 51.904L-3.02349 66.8827C-3.09166 67.258 -2.94602 67.6402 -2.64531 67.8753C-2.46093 68.0194 -2.23714 68.0938 -2.0113 68.0938C-1.86854 68.0938 -1.72495 68.0641 -1.59013 68.0036L14.9039 60.6039L16.4053 41.2334Z" fill="#757575" />
                <path d="M32.0043 39.562L16.7046 37.3743L16.2563 43.1571L32.0002 67.0665V58.3724L22.6055 43.5791L32.0043 44.7033V39.562Z" fill="#FFBB24" />
                <path d="M59.9155 35.2288L53.2586 31.5261L46.9565 32.9986L47.0354 34.0164L47.3466 38.0316L59.9155 35.2288Z" fill="#FFBB24" />
                <path d="M47.9053 45.2418L47.3085 37.5427L46.9563 32.9985L32.0042 39.562V44.7033L41.7711 40.4561L47.9053 45.2418Z" fill="#FFDB56" />
                <path d="M67.0237 66.8826L65.6408 59.2633L47.3464 38.031L47.9054 45.2413L66.667 67.8561C66.9522 67.6197 67.0901 67.2485 67.0237 66.8826Z" fill="#FFBB24" />
                <path d="M32.4213 68.0036L38.6197 65.2228L32 58.3721V68.0938H32.0001C32.1432 68.0938 32.2868 68.0641 32.4213 68.0036Z" fill="#FFDB56" />
                <path d="M61.518 36.5502C61.4631 36.2474 61.2753 35.9851 61.0062 35.8355L59.9153 35.2288L47.3464 38.0316L65.6407 59.2639L61.518 36.5502Z" fill="#616161" />
                <path d="M31.9998 68.0945C32 68.0945 32 68.0945 31.9998 68.0945V67.0668L16.2561 43.1575L14.9097 60.5263L31.5785 68.0044C31.7131 68.0648 31.8567 68.0945 31.9998 68.0945Z" fill="#616161" />
                <path fill-rule="evenodd" clip-rule="evenodd" d="M55.3182 53.9998C55.3442 54.2028 55.1928 54.3974 54.9423 54.4728C54.6061 54.574 54.2679 54.6721 53.9277 54.7669C51.3387 55.4888 48.6198 55.9295 46.2856 57.1066C42.7432 58.8931 40.2579 61.685 38.5458 64.7238C38.4513 64.8913 38.2368 64.9998 38 64.9998H51.1931C54.315 64.9998 56.8494 63.2107 56.998 60.9851L56.9983 60.9807C57.0048 60.8566 56.9928 60.7325 56.9625 60.6106C56.8429 60.1181 56.1799 57.4547 55.3182 53.9998Z" fill="#616161" />
            </g>
            <defs>
                <clipPath id="clip0_421_4435">
                    <rect width="30" height="30" fill="white" transform="translate(17.0903 3)" />
                </clipPath>
            </defs>
        </svg>

    )
}

export default AerialViewColorIcon