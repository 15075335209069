import React from 'react'
import ModalContainer from '../../modals/ModalContainer'
import FilterMain, { IFilterData } from './FilterMain'
import Button from '../../forms/Button'

type Props = {
    onChangeFilter: (name: string, value: string) => void
    onResetFilter: () => void
    filterData: IFilterData
    lang: any
    filterCount: number
    isOpen: boolean
    close: () => void
    isMobile: boolean
}


const FilterModal = (props: Props) => {
    return (
        <>
            {
                props.isMobile ?
                    <ModalContainer
                        isOpen={props.isOpen}
                        noPadding
                        fullScreen
                        positionClassname='position-huaa'
                        containerClassName='relative'
                    >
                        <div className='w-full h-full flex flex-col absolute inset-0'>
                            <div>
                                <FilterMain {...props} />
                            </div>
                            <div className='px-5 mb-5 mt-auto'>
                                <button onClick={props.close} className='w-full bg-main rounded-lg text-white py-3 text-lg font-semibold outline-none focus:outline-none'>Set Filter</button>
                            </div>
                        </div>
                    </ModalContainer> :
                    props.isOpen ?
                        <>
                            <div className='fixed inset-0 z-3' onClick={props.close}></div>
                            <FilterMain {...props} />
                        </> :
                        null
            }
        </>
    )
}

export default FilterModal